.home-page{

    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: white;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;



        img {
            width: 50px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2{
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 22px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #3BBA9C;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #3BBA9C;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #3BBA9C;
            color: #333;
        }
    }

}

.photo-container{
    width: 50%;
    height: 100%;
    top: -5%;
    bottom: -35%;
    left: 40%;
    right: 5%;
    padding-top: 20%;
    margin: auto;
    position: absolute;
    overflow: hidden;
    display: flex;

    .photoImage{
        width: 100%;
        height: auto;
    }
    
    
}

.photo-zone{

    margin-left: calc(50% - 100px);

    div{
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
    }

    p{
            font-size: 15px;
            margin-left: (-4%);
            margin-top: (10%);
            position: absolute;
            color: white;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            opacity: 0;
            animation: fadeIn 2s;
            animation-fill-mode: forwards;
            animation-delay: 5s;
    }
}

.photo1{
    transform: translateY(-150%);
    opacity: 0;
    animation: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 6s;
}

.photo2{
    transform: translateY(150%);
    opacity: 0;
    animation: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 7s;
}

.photo3{
    transform: translateX(150%);
    opacity: 0;
    animation: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 9s;
}

.photo4{
    transform: translateX(-150%);
    opacity: 0;
    animation: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 8s;
}
