.page {
    width: 100%;
    height: 100%;
    position: absolute;
    
}

.container{
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    left: 50px;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    overflow-y: scroll;
}

.about-page,
.contact-page{
    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
        

        h1{
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #3BBA9C;
            font-weight: 400;
            margin-top: 0;
            left: 40px;
            position: relative;
            margin-bottom: 20px;
        }
    }

    p{
        font-size: 15px;
        color: white;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;
        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.2s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.3s;
        }
    }
    
    .text-animate-hover{
        &:hover{
            color: white;
        }
    }

}

.portfolio-page,
.homework-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 10%;
        transform: translateY(-50%);
        width: 70%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #3BBA9C;
            font-weight: 400;
            margin-top: 0;
            left: 40px;
            position: relative;
            margin-bottom: 0px;
        }

        h2 {
            font-size: 35px;
            font-family: 'Coolvetica';
            color: #3BBA9C;
            font-weight: 400;
            margin-top: -15px;
            position: relative;
            margin-bottom: -5px;

        }

        a {
            font-size: 15px;
            color: #3BBA9C;
            text-decoration: underline;
        }

        .endLink{
            position: absolute;
            left: 270px;
        }

    }

    ul {
        list-style: none;
        position: absolute;
    }

    .projectImage {
        float: left;
        clear: left;
        margin: 0 20px 0 0;
        width: 250px;
        height: auto;
        opacity: 0;
    }

    #firstImg{
        animation: fadeInLeft 2s 2s;
        animation-fill-mode: forwards;
        clear: both;
        animation-delay: 3.1s;
    }

    #secondImg{
        animation: fadeInLeft 2s 2s;
        animation-fill-mode: forwards;
        clear: both;
        animation-delay: 3.2s;
    }

    #thirdImg{
        animation: fadeInLeft 2s 2s;
        animation-fill-mode: forwards;
        clear: both;
        animation-delay: 3.3s;
    }


    li {
        padding: 40px 0px;
        margin-top: 50px;
        opacity: 0;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
        clear: both;

    }


    #firstP {
        font-size: 15px;
        color: white;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;
        animation-delay: 3.1s;
        
    }



    #secondP {
        font-size: 15px;
        color: white;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;
        animation-delay: 3.2s;
    }



    #thirdP {
        font-size: 15px;
        color: white;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;
        animation-delay: 3.3s;
    }



    .text-animate-hover {
        &:hover {
            color: white;
        }
    }


}