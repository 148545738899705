.contact-form{
    width: 100%;
    margin-top: 20px;
    margin-left: 35px;

    ul{
        padding: 0;
        margin: 0;

        li{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half{
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child{
                margin-left: 0;
            }

        }
    }

    input[type='text'],
    input[type='email']{
        width: 100%;
        border: 0;
        background: #181818;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea{
        width: 100%;
        border: 0;
        background: #181818;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button{
        color: #3BBA9C;
        background: 0 0;
        font-size: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #3BBA9C;
        float: right;
        border-radius: 4px;

        &:hover {
            background: #3BBA9C;
            color: #333;
        }
    }
}